import axios from "axios";

export async function subscribeToPushNotifications(serviceWorkerRegistration: ServiceWorkerRegistration | null, vapidPublicKey: string) {
    let subscriptionStatus: string | null = null;
    let error: string | null = null;


    try {
        if (!serviceWorkerRegistration) {
            console.log('No registration yet, waiting for service worker');
            serviceWorkerRegistration = await navigator.serviceWorker.ready;
        }
        console.log('Using registration:', serviceWorkerRegistration.active ? 'Active' : 'Not active');

        console.log('Notification permission:', Notification.permission);
        if (Notification.permission === 'denied') {
            error = 'Notification permission denied. Please enable it in browser settings.';
            return;
        }

        console.log('Preparing VAPID key:', vapidPublicKey);
        const vapidKeyArray = urlBase64ToUint8Array(vapidPublicKey);
        console.log('VAPID key converted to Uint8Array, length:', vapidKeyArray.length);

        console.log('Attempting subscription...');
        const subscription = await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidKeyArray,
        });
        console.log('Subscription created:', subscription);

        console.log('Sending subscription to backend');
        await axios.post('/save-subscription', { subscription }).then((response) => {
            console.log('Backend response:', response.data);
            subscriptionStatus = 'Subscribed successfully';
        });
    } catch (err) {
        error = 'Subscription failed: ' + err.message;
        console.error('Subscription error:', err);
    }
    return { subscriptionStatus, error };
}

export function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}